<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">{{titlee}} Monitoring</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="year"
              class="mx-2"
              dense
              outlined
              label="Year Of"
              :items="month_of_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              outlined
              v-if="!is_forbidden"
              class="mx-2"
              v-model="selection"
              dense
              label="Selection"
              :items="['Branch','Regional','Territory']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="titlee==='Office Rental'">
            <v-select
              v-model="filter_data"
              class="mx-2"
              dense
              outlined
              label="Filter By"
              :items="['All','Good','Pending']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-card-text v-if="show" class="mt-4">
          {{ txt }}
          <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-form>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="data_items"
                    height="450"
      >
        <template v-slot:top v-if="data_items.length>0">
          <h2 v-if="!saving_data" class="mx-2">PRINT
            <v-icon
              class="mr-2"
              color="success"
              @click="print_data"
            >
              {{icons.mdiPrinter}}
            </v-icon>
          </h2>

          <v-progress-circular
            :size=50
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.branch_code }}
            </td>
            <td>
              {{item.jan===0?'': formatPrice(item.jan) }}
            </td>
            <td>
              {{item.feb===0?'': formatPrice(item.feb) }}
            </td>
            <td>
              {{item.mar===0?'': formatPrice(item.mar) }}
            </td>
            <td>
              {{item.apr===0?'': formatPrice(item.apr) }}
            </td>
            <td>
              {{item.may===0?'': formatPrice(item.may) }}
            </td>
            <td>
              {{item.jun===0?'': formatPrice(item.jun) }}
            </td>
            <td>
              {{item.jul===0?'': formatPrice(item.jul) }}
            </td>
            <td>
              {{item.aug===0?'': formatPrice(item.aug) }}
            </td>
            <td>
              {{item.sep===0?'': formatPrice(item.sep) }}
            </td>
            <td>
              {{item.oct===0?'': formatPrice(item.oct) }}
            </td>
            <td>
              {{item.nov===0?'': formatPrice(item.nov) }}
            </td>
            <td>
              {{item.dec===0?'': formatPrice(item.dec) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      txt: '',
      show: false,

      saving_data: false,
      is_forbidden: true,
      alert: false,
      option_items: [],
      category_items: [],
      month_of_items: [],
      particulars_items: [],
      branch_items: [],
      data_items: [],
      category_id: '',
      branch_id: '',
      option: '',
      particulars_id: '',
      alert_message: '',
      selection: '',
      filter_data: '',
      year: '',
      total_amount: '0.00',
      headers: [
        {text: 'Branch', value: 'date_of_deposit', sortable: false},
        {text: 'Jan.', value: 'last_name', sortable: false},
        {text: 'Feb.', value: 'last_name', sortable: false},
        {text: 'Mar.', value: 'last_name', sortable: false},
        {text: 'Apr.', value: 'last_name', sortable: false},
        {text: 'May', value: 'last_name', sortable: false},
        {text: 'Jun.', value: 'last_name', sortable: false},
        {text: 'Jul.', value: 'last_name', sortable: false},
        {text: 'Aug.', value: 'last_name', sortable: false},
        {text: 'Sep.', value: 'last_name', sortable: false},
        {text: 'Oct.', value: 'last_name', sortable: false},
        {text: 'Nov.', value: 'last_name', sortable: false},
        {text: 'Dec.', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    props: {
      titlee: String
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_data', ['bills_monitoring',]),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      ...mapActions('category', ['list_of_all_active_categories']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_all_active_categories()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        if (this.is_forbidden) {
          await this.selected_selection()
        }
        if (!this.is_forbidden && this.selection != '') {
          await this.selected_selection()
        }

      },
      async selected_selection() {
        this.alert = false
        this.show = true
        this.txt = 'SEARCHING ' + this.titlee
        this.data_items = []
        if (this.$refs.form.validate()) {
          await this.bills_monitoring({
            year: this.year,
            category_id: this.category_id,
            filter_data: this.filter_data,
            is_region: this.selection === 'Regional' ? 1 : 0,
            is_territory: this.selection === 'Territory' ? 1 : 0,
            particulars: this.titlee,
          })
            .then(response => {
              this.data_items = response.data
              this.txt = ''
              this.show = false
            })
            .catch(error => {
              console.log(error)
            })
        }else {
          this.txt = ''
          this.show = false
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []

          widths = [150, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50]
          payments_array.push([
            {text: 'Branch', alignment: 'left', style: 'main_info',},
            {text: 'Jan.', alignment: 'left', style: 'main_info'},
            {text: 'Feb.', alignment: 'left', style: 'main_info'},
            {text: 'Mar.', alignment: 'left', style: 'main_info'},
            {text: 'Apr.', alignment: 'left', style: 'main_info'},
            {text: 'May', alignment: 'left', style: 'main_info'},
            {text: 'Jun.', alignment: 'left', style: 'main_info'},
            {text: 'Jul.', alignment: 'left', style: 'main_info'},
            {text: 'Aug.', alignment: 'left', style: 'main_info'},
            {text: 'Sep.', alignment: 'left', style: 'main_info'},
            {text: 'Oct.', alignment: 'left', style: 'main_info'},
            {text: 'Nov.', alignment: 'left', style: 'main_info'},
            {text: 'Dec.', alignment: 'left', style: 'main_info'},
          ])
          var jan = 0;
          var feb = 0;
          var mar = 0;
          var apr = 0;
          var may = 0;
          var jun = 0;
          var jul = 0;
          var aug = 0;
          var sep = 0;
          var oct = 0;
          var nov = 0;
          var dec = 0;
          this.data_items.forEach(function (item, index, payment) {
            jan += parseFloat(item.jan);
            feb += parseFloat(item.feb);
            mar += parseFloat(item.mar);
            apr += parseFloat(item.apr);
            may += parseFloat(item.may);
            jun += parseFloat(item.jun);
            jul += parseFloat(item.jul);
            aug += parseFloat(item.aug);
            sep += parseFloat(item.sep);
            oct += parseFloat(item.oct);
            nov += parseFloat(item.nov);
            dec += parseFloat(item.dec);
            payments_array.push([
              {
                text: item.branch_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jan === 0 ? '' : (item.jan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.feb === 0 ? '' : (item.feb / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.mar === 0 ? '' : (item.mar / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.apr === 0 ? '' : (item.apr / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.may === 0 ? '' : (item.may / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jun === 0 ? '' : (item.jun / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jul === 0 ? '' : (item.jul / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.aug === 0 ? '' : (item.aug / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.sep === 0 ? '' : (item.sep / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.oct === 0 ? '' : (item.oct / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.nov === 0 ? '' : (item.nov / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.dec === 0 ? '' : (item.dec / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
            if (Object.is(payment.length - 1, index)) {
              payments_array.push([
                {
                  text: 'TOTAL',
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: jan === 0 ? '' : (jan / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: feb === 0 ? '' : (feb / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: mar === 0 ? '' : (mar / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: apr === 0 ? '' : (apr / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: may === 0 ? '' : (may / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: jun === 0 ? '' : (jun / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: jul === 0 ? '' : (jul / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: aug === 0 ? '' : (aug / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: sep === 0 ? '' : (sep / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: oct === 0 ? '' : (oct / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: nov === 0 ? '' : (nov / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: dec === 0 ? '' : (dec / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
              ])
            }
          })

          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    {
                      text: this.category_items[this.category_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.category_id)].category,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Year of: ',
                    {
                      text: this.year,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'landscape',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'HILARIO GENERAL ENTERPRISE',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: this.titlee + ' Monitoring',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
